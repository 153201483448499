@import "../include-media.scss";
$breakpoints: (
  small: 330px,
  medium: 500px,
  large: 1024px,
);

.background {
  background-color: white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.header-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}

.header-container {
  width: 100%;
  align-items: center;

  &-image {
    img {
      height: 50px;
      width: 100px;
      object-fit: contain;
      padding: 10px;
      border-right: 0.5px solid rgba(0, 0, 0, 0.15);
    }
    background-color: black;
  }

  &-title {
    padding: 15px;

    h2 {
      color: black;
      font-family: "Helvetica";
      font-size: 14px;
      letter-spacing: 0.5px;
      line-height: 16px;
    }
  }
}

.vl {
  border-left: 1px solid gray;
  height: 20px;
}

hr {
  border: 0.5px solid rgba(0, 0, 0, 0.15);
}

@include media("<medium") {
  .header-container {
    width: 100%;
    align-items: center;

    &-title {
      padding: 15px;

      h2 {
        color: #002856;
        font-family: "Helvetica";
        font-size: 9px;
      }
    }
  }
}

@include media("<small") {
  .header-container {
    width: 100%;
    align-items: center;

    &-image {
      img {
        height: 50px;
        width: 70px;
        object-fit: contain;
        padding: 5px;
        border-right: 0.5px solid rgba(0, 0, 0, 0.15);
      }
    }

    &-title {
      h2 {
        color: #002856;
        font-family: "Helvetica";
        font-size: 9px;
      }
    }
  }
}
