@import "../include-media.scss";
$breakpoints: (
  small: 450px,
  medium: 867px,
  large: 1024px,
);

.container {
  margin: 0;
  padding-bottom: 56px;
  position: relative;
}

.header {
  width: 664px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Helvetica";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 36px;
  text-align: left;
}

.subtitle {
  margin-top: 15px;
  height: 21px;
  width: 664px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Helvetica";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: left;
}

.donate-page-container {
  margin: 30px auto;
  height: 305px;
  width: 645px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  background-color: #ffffff;
  padding: 20px;

  hr {
    width: 90%;
    margin: 10px;
  }

  &-title {
    text-align: center;

    p {
      margin-top: 20px;
      color: rgba(0, 0, 0, 0.54);
      font-family: "Helvetica";
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.5px;
    }

    button {
      margin-top: 10px;
      height: 56px;
      width: 90%;
      border-radius: 8px;
      background-color: #f37037;
      color: white;
      font-family: "Helvetica";
      font-size: 16px;
      border: none;
    }
  }
}

.note {
  margin-top: 20px;
  height: 54px;
  width: 90%;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Helvetica";
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 18px;
  text-align: left;
}

.checkout-form {
  input {
    margin: 10px;
    text-indent: 10px;
    width: 90%;
    height: 56px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.54);
    font-family: "Helvetica";
    font-size: 16px;
    border-radius: 4px;
    letter-spacing: 0;
    line-height: 22px;
    outline: none;
  }

  .notification {
    height: 18px;
    width: 90%;
    color: rgba(0, 0, 0, 0.44);
    font-family: "Helvetica";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: left;
    margin-bottom: 20px;
  }
}

.StripeElement {
  display: block;
  margin: 20px 0 20px 0;
  padding: 20px 2px;
  width: 90%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.54);
  font-family: "Helvetica";
  font-size: 16px;
  border-radius: 4px;
  letter-spacing: 0;
  text-indent: 10px;
}

@include media("<medium") {
  .donate-page-container {
    margin: 30px auto;
    height: 305px;
    width: 90%;
  }

  .note {
    width: 90%;
  }

  .header {
    width: 90%;
  }

  .subtitle {
    width: 90%;
  }
}

@include media("<small") {
  .donate-page-container {
    margin: 30px auto;
    height: 300px;
    width: 80%;
  }

  .note {
    width: 90%;
    height: 120px;
  }

  .header {
    width: 90%;
    margin-bottom: 40px;
  }

  .subtitle {
    width: 90%;
  }
}
